import React, { useState, useEffect } from 'react'
import MasterPage from '../Templates/MasterPage'
import Footer from '../Templates/Footer'
import { server } from '../server'

import { Company_Id } from '../Helper'
import { Corporate_Id } from '../Helper'
import { Corporate_Name } from '../Helper'
import { Vendor_Id } from '../Helper'
import { Vendor_Name } from '../Helper'
import Swal from 'sweetalert2'

import { Navigate } from 'react-router'
import { Link, useNavigate, useLocation } from 'react-router-dom';


export default function Canceled() {
    const navigate = useNavigate();
    const JWTToken = localStorage.getItem('Token');
    const statusStyle = {
        backgroundColor: '#3498db',
        color: 'white',
        padding: '1px 5px 4px 4px'
    }
    const [upcomingTrips, setupcomingTrips] = useState([]);
    useEffect(() => {
        let userMobNo = localStorage.getItem('contactNo');
        

        fetch(`${server}/api/O3Mobility/TripHistoryO3/${Company_Id}/${Corporate_Id}/${Corporate_Name}/${Vendor_Id}`,
            { method: 'GET', headers: { 'Authorization': `${JWTToken}`, 'Content-Type': 'application/json', }}
        )
            .then((result) => {
                result.json().then((resp) => {
                    //  setCompletedTrips(resp.lsttripHistory);
                        if (resp.statusCode == 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Session Expired',
                            });
                            localStorage.clear();
                            navigate('/Login');

                         }


                    // const updatedList = resp.lsttripHistory.filter(item => item.tripStatus === 'Cancelled');
                    // setupcomingTrips(updatedList);

                    let updatedList = [];

                    if (resp.lsttripHistory) 
                        { const updatedList = resp.lsttripHistory.filter(item => item.tripStatus === 'Cancelled'); setupcomingTrips(updatedList); } else { console.error('lsttripHistory is null'); }

                    // var updatedList = [...resp.lsttripHistory];
                    // updatedList = updatedList.filter((item) => {
                    //     return item.tripStatus == 'Cancelled';
                    // });
                    // setupcomingTrips(updatedList);
                })

            })
    }, [])

    return (
        <div>
            <>
                <div>
                    <MasterPage />
                </div>
                <div>
                    <div className="content-wrapper" style={{ minHeight: '704.8px' }}>
                        {/* Content Header (Page header) */}
                        <section className="content-header">
                            <div className="container-fluid">
                                <div className="row mb-2">
                                    <div className="col-sm-6">
                                        <h1>Canceled Trips</h1>
                                    </div>
                                    <div className="col-sm-6">
                                        <ol className="breadcrumb float-sm-right">
                                            <li className="breadcrumb-item"><a href="#">Home</a></li>
                                            <li className="breadcrumb-item active">Canceled Trips</li>
                                        </ol>
                                    </div>
                                </div>
                            </div>{/* /.container-fluid */}
                        </section>
                        {/* Main content */}
                        <section className="content">
                            <div className="container-fluid">

                                <div className="card card-warning">
                                    <div className="card-header">
                                        <h3 className="card-title">Canceled Trips Details</h3>
                                    </div>
                                    <div className="card-body">
                                        <div className='row'>
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th scope="col">SrNo.</th>
                                                        <th scope="col">BookingID</th>
                                                        <th scope="col">Booking Date</th>
                                                        <th scope="col">Trip Date</th>
                                                        <th scope="col">Pickup Location</th>
                                                        <th scope="col">Drop Location</th>
                                                        <th scope="col">Fare</th>
                                                        <th scope="col">PaymentType</th>
                                                        {/* <th scope="col">Trip Fare</th> */}
                                                        <th>Status</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {upcomingTrips.length === 0 ? (
                                                    <tr>
                                                        <td colSpan="9">No Record</td>
                                                    </tr>
                                                    ) : (
                                                    upcomingTrips.map((data, index) => (
                                                        <tr key={data.id}>
                                                        <td>{index + 1}.</td>
                                                        <td>{data.id}</td>
                                                        <td>{data.jobCreationTime}</td>
                                                        <td>{data.tripDateTime}</td>
                                                        <td>{data.pickupAddress}</td>
                                                        <td>{data.dropAddress}</td>
                                                        <td>{data.calculatedBasefare}</td>
                                                        <td>{data.billingType}</td>
                                                        {/* <td>{data.tripFair}</td> */}
                                                        <td>
                                                            <small style={statusStyle}>{data.tripStatus}</small>
                                                        </td>
                                                        </tr>
                                                    ))
                                                    )}

                                                </tbody>
                                            </table>


                                        </div>


                                    </div>
                                    {/* /.card-body */}
                                </div>
                            </div>{/*/. container-fluid */}
                        </section>
                        {/* /.content */}
                    </div>
                </div>
                <div>
                    <Footer />
                </div>
            </>
        </div>
    )
}
