import React, { Component } from 'react'
import { Link, useNavigate } from 'react-router-dom';

export default class LeftMenu extends Component {
  render() {
    return (
      <div>
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
          {/* Brand Logo */}
          <a href="https://eveelz.in/" target='_blank' className="brand-link" style={{background:'white'}}>
          <img src="dist/img/logo.webp" alt="logo" style={{width:'150px', textAlign:'center'}} />
            {/* <img src="dist/img/AdminLTELogo.png" alt="AdminLTE Logo" className="brand-image img-circle elevation-3" style={{ opacity: '.8' }} />
            <span className="brand-text font-weight-light">Refex Green </span> */}
          </a>
          {/* Sidebar */}

          <div className="sidebar">
            {/* Sidebar user panel (optional) */}

            {/* SidebarSearch Form */}
            <div className="form-inline" style={{ display: 'none' }}>
              <div className="input-group" data-widget="sidebar-search">
                <input className="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search" />
                <div className="input-group-append">
                  <button className="btn btn-sidebar">
                    <i className="fas fa-search fa-fw" />
                  </button>
                </div>
              </div>
            </div>
            {/* Sidebar Menu */}
            <nav className="mt-2">
              <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                <li className="nav-header">BOOKING</li>
                {/* <li className="nav-item">
                  <a href="#" className="nav-link">
                    <i className="nav-icon fas fa-tachometer-alt" />
                    <Link to="/Dashboard">Dashboard</Link>
                  </a>
                </li> */}
                

<li className="nav-item">
  <Link to="/Booking" className="nav-link">
    <i className="nav-icon fa fa-solid fa-car text-info" />
    Book Trips
  </Link>
</li>
<li className="nav-item">
  <Link to="/Upcomingtrips" className="nav-link">
    <i className="nav-icon fa fa-solid fa-car text-warning" />
    Upcoming Trips
  </Link>
</li>
<li className="nav-item">
  <Link to="/Completed" className="nav-link">
    <i className="nav-icon fa fa-solid fa-car text-info" />
    Completed Trips
  </Link>
</li>
<li className="nav-item">
  <Link to="/Rejected" className="nav-link">
    <i className="nav-icon fa fa-solid fa-car text-danger" />
    Rejected Trips
  </Link>
</li>
<li className="nav-item">
  <Link to="/Canceled" className="nav-link">
    <i className="nav-icon fa fa-solid fa-car text-danger" />
    Canceled Trips
  </Link>
</li>


                {/* <li className="nav-item">
                  <a href="#" className="nav-link">
                    <i className="nav-icon fa fa-solid fa-car text-danger" />
                    <Link to="/ccavenue">CCAvenue-Pay</Link>
                  </a>
                </li> */}
               

              </ul>
            </nav>
            {/* /.sidebar-menu */}
          </div>
          {/* /.sidebar */}
        </aside>

      </div>
    )
  }
}
