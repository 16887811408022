import React, { useState, useEffect } from 'react'
import MasterPage from '../Templates/MasterPage'
import Footer from '../Templates/Footer'
import {server} from '../server'

import { Company_Id } from '../Helper'
import { Corporate_Id } from '../Helper'
import { Corporate_Name } from '../Helper'
import { Vendor_Id } from '../Helper'
import { Vendor_Name } from '../Helper'
import Swal from 'sweetalert2'
import { Navigate } from 'react-router'
import { Link, useNavigate, useLocation } from 'react-router-dom';


//export default class UpcomingTrips extends Component {
    function UpcomingTrips() {
        const navigate = useNavigate();
        let JWTToken = localStorage.getItem('Token');
        const statusStyle={
            backgroundColor: '#3498db',
            color: 'white',
            padding: '1px 5px 4px 4px'
        }
        const [upcomingTrips, setupcomingTrips] = useState([]);
        let userMobNo=localStorage.getItem('contactNo');
        
        // useEffect(() => {
        //     fetch(`${server}/api/O3Mobility/TripHistoryO3/${Company_Id}/${Corporate_Id}/${Corporate_Name}/${Vendor_Id}/${userMobNo}`)
        //         .then((result) => {
        //             console.log("---------");
        //             console.log(result);
        //             result.json().then((resp) => {
        //               //  setCompletedTrips(resp.lsttripHistory);
        //                 var updatedList = [...resp.lsttripHistory];
        //                 updatedList = updatedList.filter((item) => {
        //                     return item.tripStatus== 'Not Started' || item.tripStatus=='Dispatching';
        //                 });
        //                 setupcomingTrips(updatedList);
        //             })
    
        //         })
        // }, [])



        function CancelRide(JobNo){
            // console.log(JobNo);
        }

        useEffect(() => {
            //console.log("---------");
            fetch(`${server}/api/O3Mobility/TripHistoryO3/${Company_Id}/${Corporate_Id}/${Corporate_Name}/${Vendor_Id}`,
                { method: 'GET', headers: { 'Authorization': `${JWTToken}`, 'Content-Type': 'application/json', }}
            )
                .then((result) => {
                    // console.log("---------");
                    // console.log(result.json());
                    // console.log(result.status);
                    if (result.status === 404) {
                        console.error('Error 404: Resource not found.');
                        // Handle the 404 error as needed
                        return;
                    }
                    // console.log(result);
        
                    result.json().then((resp) => {
                         //console.log(resp);
                         if (resp.statusCode == 401) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Session Expired',
                            });
                            localStorage.clear();
                            navigate('/Login');

                         }
                        if (Array.isArray(resp.lsttripHistory)) {
                            var updatedList = [...resp.lsttripHistory];
                            updatedList = updatedList.filter((item) => {
                                return item.tripStatus == 'Not Started' || item.tripStatus == 'Dispatching';
                            });
                            setupcomingTrips(updatedList);
                            // console.log(upcomingTrips);
                        } else {
                            console.error('lsttripHistory is not an array:', resp.lsttripHistory);
                        }
                    }).catch((error) => {
                        console.error('Error parsing JSON:', error);
                    });
        
                }).catch((error) => {
                    console.error('Fetch error:', error);
                    // Handle other errors as needed
                });
        }, []);
        //useEffect(() => { console.log(upcomingTrips); }, [upcomingTrips]);
    
        return (
            <div>
                <>
                    <div>
                        <MasterPage />
                    </div>
                    <div>
                        <div className="content-wrapper" style={{ minHeight: '704.8px' }}>
                            {/* Content Header (Page header) */}
                            <section className="content-header">
                                <div className="container-fluid">
                                    <div className="row mb-2">
                                        <div className="col-sm-6">
                                            <h1>Upcoming Trips</h1>
                                        </div>
                                        <div className="col-sm-6">
                                            <ol className="breadcrumb float-sm-right">
                                                <li className="breadcrumb-item"><a href="#">Home</a></li>
                                                <li className="breadcrumb-item active">Upcoming Trips</li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>{/* /.container-fluid */}
                            </section>
                            {/* Main content */}
                            <section className="content">
                                <div className="container-fluid">

                                    <div className="card card-warning">
                                        <div className="card-header">
                                            <h3 className="card-title">Upcoming Trips Details</h3>
                                        </div>
                                        <div className="card-body">
                                        <div className='row'>
                                        <table className="table table-striped">
    <thead>
        <tr>
            <th scope="col">SrNo.</th>
            <th scope="col">BookingID</th>
            <th scope="col">Booking Date</th>
            <th scope="col">Trip Date</th>
            <th scope="col">Pickup Location</th>
            <th scope="col">Drop Location</th>
            <th scope="col">Fare</th>
            <th scope="col">PaymentType</th>
            {/* <th scope="col">Trip Fare</th> */}
            <th>Status</th>
            {/* <th>Action</th>  */}
        </tr>
    </thead>
    <tbody>
    {
        upcomingTrips && upcomingTrips.length > 0 ?
        upcomingTrips.map((data, index) => {
            // Assuming tripDateTime is in "dd-MM-yyyy HH:mm:ss" format
            const [day, month, year, time] = data.tripDateTime.split(/[- :]/);
            const tripDateTime = new Date(`${year}-${month}-${day}T${time}:00`);
            const currentTime = new Date();
            const timeDiff = (tripDateTime - currentTime) / (1000 * 60 * 60); // Difference in hours

            return (
                <tr key={data.id}>
                    <td>{index + 1}.</td>
                    <td>{data.id}</td>
                    <td>{data.jobCreationTime}</td>
                    <td>{data.tripDateTime}</td>
                    <td>{data.pickupAddress}</td>
                    <td>{data.dropAddress}</td>
                    <td>{data.calculatedBasefare}</td>
                    <td>{data.billingType}</td>
                    <td><small style={statusStyle}>{data.tripStatus}</small></td>
                    <td>
                        {timeDiff > 3 && (
                            <button onClick={() => CancelRide(data.id)}>Cancel</button>
                        )}
                    </td>
                </tr>
            );
        }) :
        <>No record</>
    }
</tbody>

</table>



                                        </div>


                                        </div>
                                        {/* /.card-body */}
                                    </div>
                                </div>{/*/. container-fluid */}
                            </section>
                            {/* /.content */}
                        </div>
                    </div>
                    <div>
                        <Footer />
                    </div>
                </>
            </div>
        )
    }
export default UpcomingTrips;

