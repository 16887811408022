import React, { useState, useEffect } from 'react'
import MasterPage from '../Templates/MasterPage'
import Footer from '../Templates/Footer'
import { Autocomplete, useLoadScript } from "@react-google-maps/api";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import Swal from 'sweetalert2'
import { server } from '../server'
import { setKey, setDefaults, fromLatLng, setLocationType, RequestType } from "react-geocode";
import { Link, useNavigate, useLocation } from 'react-router-dom';

import { Company_Id } from '../Helper'
import { Corporate_Id } from '../Helper'
import { Corporate_Name } from '../Helper'
import { Vendor_Id } from '../Helper'
import { Vendor_Name } from '../Helper'
import axios from 'axios';

const placesLibrary = ["places"];
function Booking() {

    const navigate = useNavigate();

    let userId = localStorage.getItem('userid');
    let userMobNo = localStorage.getItem('contactNo');
    let userName = localStorage.getItem('userName');
    let userEmailId = localStorage.getItem('emailId');
    let JWTToken = localStorage.getItem('Token');


    // const { state } = useLocation();
    // let userId=state.logingDetails.userId;
    // let userMobNo = state.logingDetails.contactNo;
    // let userName = state.logingDetails.userName;
    // let userEmailId = state.logingDetails.emailId;

    //debugger;

    setDefaults({
        key: "AIzaSyB8YbkwvUiwIUxKek_hMCPXBu9jEROJwL4", // Your API key here.
        language: "en", // Default language for responses.
        region: "es", // Default region for responses.
    });
 
    const [searchResultFrom, setSearchResultFrom] = useState(null);
    const [searchResultTo, setSearchResultTo] = useState(null);
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: "AIzaSyB8YbkwvUiwIUxKek_hMCPXBu9jEROJwL4",
        libraries: placesLibrary
    });

    //----=============== Local db details =================
    // CompanyId: "13960",
    // CorporateId: "13611",
    // CorporateName: "O3MPLRetail",
    // VendorId: "2584",
    // VendorName:"O3MPL",

    //=============== Live server details ===================
    // CompanyId: "13996",
    // CorporateId: "13647",
    // CorporateName: "O3MPLRetail",
    // VendorId: "2587",
    // VendorName:"O3MPL",

    const initialValues = {
        CompanyId: "13996",
        CorporateId: "13647",
        CorporateName: "O3MPLRetail",
        VendorId: "2587",
        VendorName: "O3MPL",
        OperatorID: "Website",
        CallerID: userMobNo,
        CallerName: userName,
        TaxiCategoryId: "",//"2",
        TaxiCategory: "",//"Sedan",
        RatePlanId: "13",
        TariffId: "",// "1464266",
        Tariff: "",//"AIRPORT TO CITY",
        RequestPickUpTime: "",// "2023-09-10 12:20:00",
        NoOfDays: "1",
        PickupAddress: "",// "KR Puram",
        PickupMessage: "",
        Pickuplat: "",
        Pickuplon: "",
        DropOffAddress: "",// "Mekhri circle",
        DropOfflat: "",//"77.98",
        DropOfflon: "",// "13.999",
        TypeOfTripId: "",//"1464266",
        Noofpassengers: "3",
        NoOFVehicles: "1",
        GuestEmailId: "",
        GuestMobileNum: "",
        paymenttypeid: "2",
        paymenttype: "Cash",
        email: userEmailId,
        BookingFare: 0,
        BookingType: "",
        CalculatedBaseFare: 0,
        JWTToken:localStorage.getItem('Token')
    }


    const [formvalue, setFormValue] = useState(initialValues)
    const [formErrors, setFormErrors] = useState({});
    const [IsSubmit, setIsSubmit] = useState(false);

    const [corportate, setCorporate] = useState([
        { ValueId: "13647", valueText: "O3MPLRetail" }
    ]);

    const [vechaleCat, setvechaleCat] = useState([]);
    async function Get_VehicleCategory() {
         try {
            const response = await fetch(`${server}/api/O3Mobility/VehicleCategoryGet/${Company_Id}/${Corporate_Id}/${Vendor_Id}`,
                 { method: 'GET', headers: { 'Authorization': `${JWTToken}`, 'Content-Type': 'application/json', // Add more headers as needed 
            } });
    if (!response.ok) { 
        throw new Error('Network response was not ok'); 
    } 
    const body = await response.json(); 
    if(response.status==401){
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Session Expired Please Login Again',
        })
        navigate('/Login');
    }

    setvechaleCat(body.vehicleCat_lst); } 
    

    catch (error) {
         console.error('There was a problem with the fetch operation:', error);
         Swal.fire({
                 icon: 'error',
                 title: 'Oops...',
                 text: 'SomeThing Went wrong Please Try Again',
         
               })
     } }
    //tariff details...................
    const [tripType, setTripType] = useState([]);

    async function Get_Traiff() {
        try {
            const response = await fetch(`${server}/api/O3Mobility/TariffMasterGet/${Company_Id}/${Corporate_Id}/${Vendor_Id}`, {
                method: 'GET',
                headers: {
                    'Authorization': `${JWTToken}`,
                    'Content-Type': 'application/json',
                }
            });
            const body = await response.json();
            // console.log('Response body:', body);
            if (body.statusCode === 401) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Session Expired. Please Login Again.',
                });
                navigate('/Login');
                return; // Stop further execution
            }
            if (!response.ok) {
                throw new Error(`Network response was not ok: ${response.statusText}`);
            }
            setTripType(body.tarifflist);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong. Please try again.',
            });
        }
    }
    

    
    // async function Get_Traiff() {
    //      try { const response = await fetch(`${server}/api/O3Mobility/TariffMasterGet/${Company_Id}/${Corporate_Id}/${Vendor_Id}`,
    //         { method: 'GET', headers: { 'Authorization': `${JWTToken}`, 'Content-Type': 'application/json', 
    //      }});
    //       if (!response.ok) { throw new Error('Network response was not ok'); }
    //       console.log('response');
    //       console.log('response',response);
    //       console.log(response);
          
    //       if(response.status==401){
    //             Swal.fire({
    //                 icon: 'error',
    //                 title: 'Oops...',
    //                 text: 'Session Expired Please Login Again',
    //             })
    //             navigate('/Login');
    //       }
    //        const body = await response.json();
    //         setTripType(body.tarifflist); } 
    //        catch (error) { 
    //         console.error('There was a problem with the fetch operation:', error);
    //         Swal.fire({
    //             icon: 'error',
    //             title: 'Oops...',
    //             text: 'SomeThing Went wrong Please Try Again',
    //           })
    //     }
    //      }
    const handChange = (e) => {
        const { name, value } = e.target;
        setFormValue({ ...formvalue, [name]: value });
        get_EstimateFare();
        if (name == 'RequestPickUpTime') {
            const selectedDate = new Date(e.target.value);
            const currentDate = new Date();
            const timeDifference = (selectedDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60);
            if (timeDifference < 4) {
                formErrors.RequestPickUpTime = 'Selected date should be at least 4 hours ahead of the current date and time.';
                setFormValue({ ...formvalue, [name]: '' });
            } else {
                const formattedDate = selectedDate.toISOString().slice(0, 16);
                formvalue.RequestPickUpTime = formattedDate
                formErrors.RequestPickUpTime = '';
                setFormValue({ ...formvalue, [name]: value });
            }
        }
        // console.log('form-val-', formvalue);
    }
    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormErrors(validate(formvalue));
        setIsSubmit(true);
    }
    async function SubmitDetails(formvalue) {
        navigate('/Bookingconfirm', { state: { formvalue } });
    }
    useEffect(() => {
        // console.log(JWTToken);
        Get_VehicleCategory();
        Get_Traiff();
        if (Object.keys(formErrors).length == 0 && IsSubmit) {
            // console.log(formvalue);
            //  debugger;
            SubmitDetails(formvalue);
        }
    }, [formErrors]);

    const validate = (value) => {
        const errors = {};
        formvalue.CallerName = document.getElementById("CallerName").value;
        formvalue.email = document.getElementById("emailId").value;
        const tripType = document.getElementById('selectedTripId').value;

        var vm = document.getElementById("vehiclemodal");
        formvalue.TaxiCategory = vm.options[vm.selectedIndex].text;
        formvalue.TaxiCategoryId = vm.options[vm.selectedIndex].value;

        var e = document.getElementById("TariffId");
        formvalue.Tariff = e.options[e.selectedIndex].text;
        formvalue.TariffId = e.options[e.selectedIndex].value;
        formvalue.TypeOfTripId = e.options[e.selectedIndex].value;

        formvalue.PickupAddress = document.getElementById("PickupAddress").value;
        formvalue.DropOffAddress = document.getElementById("dropAddress").value;
        formvalue.BookingFare = document.getElementById("fareValueId").innerHTML;
        formvalue.CalculatedBaseFare = document.getElementById("fareValueId").innerHTML;

        if (!value.CallerName) {
            errors.CallerName = "Full name is required";
        }

        if (!value.PickupAddress) {
            errors.PickupAddress = "From location is required";
        }

        if (!value.DropOffAddress) {
            errors.DropOffAddress = "To location is required";
        }

        if (!value.BookingFare) {
            errors.BookingFare = "Select trip type and vehicle category";
        }
        const distances = CheckForSevicableArea();
        // console.log('distances', distances);

    if(distances.distanceFromAirportToPickup >= 2 && distances.distanceFromDropOffToAirport >= 2){
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Only Airport to City and City to Airport services are available',
        });
        errors.rejectreason="Only Airport to City and City to Airport services are available";
        return errors;
    }else if(distances.distanceBetweenPickupAndDropOff > 70){
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Within city only services are available',
        });
        errors.rejectreason="Only Within city services are available";
        return errors;
    }


        if (!value.r1) {
            errors.r1 = "Select booking type";
            return errors;
        }
       
        if (value.r1 === 'bookltr') {
            if (!value.RequestPickUpTime) {
                errors.RequestPickUpTime = "Pickup time is required";
            } else {
                formvalue.BookingType = 'bookltr';
            }
            // const distances = CheckForSevicableArea();
            // // console.log('distances', distances);
            // if (distances.distanceFromAirportToPickup >= 2 || distances.distanceFromDropOffToAirport >= 2) {
            //     Swal.fire({
            //         icon: 'error',
            //         title: 'Oops...',
            //         text: 'Only Airport to City and City to Airport services are available',
            //     });
            //     return errors
                
            // } else if (distances.distanceBetweenPickupAndDropOff > 70) {
            //     Swal.fire({
            //         icon: 'error',
            //         title: 'Oops...',
            //         text: 'Within city only services are available',
            //     });
            //     return errors;
                
            // }
        }

        if (value.r1 === 'booknow') {
            // const distances = CheckForSevicableArea();
            // alert(distances.distanceFromAirportToPickup);
            // alert(distances.distanceFromDropOffToAirport);
            // alert(distances.distanceBetweenPickupAndDropOff);
            // if (distances.distanceFromAirportToPickup >= 2 || distances.distanceFromDropOffToAirport >= 2) {
            //     Swal.fire({
            //         icon: 'error',
            //         title: 'Oops...',
            //         text: 'Only Airport to City and City to Airport services are available',
            //     });
            //     return errors;
            // } else if (distances.distanceBetweenPickupAndDropOff > 70) {
            //     Swal.fire({
            //         icon: 'error',
            //         title: 'Oops...',
            //         text: 'Within city only services are available',
            //     });
            //     return errors;
            // }

            const currentDate = new Date();
            currentDate.setHours(currentDate.getHours() + 4); // Add 4 hours

            const year = currentDate.getFullYear();
            const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
            const day = String(currentDate.getDate()).padStart(2, '0');
            const hours = String(currentDate.getHours()).padStart(2, '0');
            const minutes = String(currentDate.getMinutes()).padStart(2, '0');

            const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}`;
            formvalue.RequestPickUpTime = formattedDateTime;
            formvalue.BookingType = 'booknow';
        }

        return errors;
    };


//     const validate = (value) => {
//         const errors = {};
//         formvalue.CallerName = document.getElementById("CallerName").value;
//         formvalue.email = document.getElementById("emailId").value;
//         const tripType = document.getElementById('selectedTripId').value;

//         var vm = document.getElementById("vehiclemodal");
//         formvalue.TaxiCategory = vm.options[vm.selectedIndex].text;
//         formvalue.TaxiCategoryId = vm.options[vm.selectedIndex].value;

//         var e = document.getElementById("TariffId");
//         formvalue.Tariff = e.options[e.selectedIndex].text;
//         formvalue.TariffId = e.options[e.selectedIndex].value;
//         formvalue.TypeOfTripId = e.options[e.selectedIndex].value;

//         formvalue.PickupAddress = document.getElementById("PickupAddress").value;
//         formvalue.DropOffAddress = document.getElementById("dropAddress").value;
//         formvalue.BookingFare = document.getElementById("fareValueId").innerHTML;
//         formvalue.CalculatedBaseFare = document.getElementById("fareValueId").innerHTML;
       

//         // console.log('value data', value);
//         if (!value.CallerName) {
//             errors.CallerName = "Full name is required";
//         }
//         // if ((!value.TaxiCategoryId) || (value.TaxiCategoryId === 'Select')) {
//         //     errors.TaxiCategoryId = "Vehicle category is required";
//         // }

//         // if (!value.TariffId) {
//         //     errors.TariffId = "Trip type is required";
//         // }
//         // if (tripType === '2') {
//         //     errors.TariffId = "Sorry !! Out Station is not activated";
//         // }
//         // if (tripType === '4') {
//         //     errors.TariffId = "Sorry !! Book Now is not activated.";
//         // }

//         if (!value.PickupAddress) {
//             errors.PickupAddress = "From location is required";
//         }
//         if (!value.DropOffAddress) {
//             errors.DropOffAddress = "To location is required";
//         }

//         // if (!value.email) {
//         //     errors.emailId = "Email id is required";
//         // }
//         if (!value.BookingFare) {
//             errors.BookingFare = "Select trip type and vehicle category";
//         }
//         if (!value.r1) {
//             errors.r1 = "Select booking type"
//         }

//         if (value.r1 === 'bookltr') {
//             const distances=CheckForSevicableArea();
//             if (distances.distanceFromAirportToPickup >= 2 || distances.distanceFromDropOffToAirport >= 2) {
//                       Swal.fire({
//                               icon: 'error',
//                               title: 'Oops...',
//                               text: 'Only Airport to City and City to Airport services are available',
//                             });return;}
//                        else if (distances.DISTANCEBWatob >70 ) {
//                          Swal.fire({
//                              icon: 'error',
//                              title: 'Oops...',
//                              text: 'within city only services are available',
                     
//                            });return; }
                          

//             if (!value.RequestPickUpTime) {
//                 errors.RequestPickUpTime = "Pickup time is required";
//             }
//             else {
//                 formvalue.BookingType = 'bookltr';
//             }
//         }
//         // console.log(distances.distanceFromAirportToPickup);
//         // console.log(distances.distanceFromDropOffToAirport);
//         // console.log(distances.DISTANCEBWatob);
//         if (value.r1 === 'booknow') {
//             const distances=CheckForSevicableArea();
//   if (distances.distanceFromAirportToPickup >= 2 || distances.distanceFromDropOffToAirport >= 2) {
//             Swal.fire({
//                     icon: 'error',
//                     title: 'Oops...',
//                     text: 'Only Airport to City and City to Airport services are available',
//                   });return;}
//              else if (distances.DISTANCEBWatob >70 ) {
//                Swal.fire({
//                    icon: 'error',
//                    title: 'Oops...',
//                    text: 'within city only services are available',
           
//                  });return; }
                




//             // const currentDate = new Date();
//             // // Get the current date components
//             // const year = currentDate.getFullYear();
//             // const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
//             // const day = String(currentDate.getDate()).padStart(2, '0');
//             // // Get the current time components
//             // const hours = String(currentDate.getHours()).padStart(2, '0');
//             // const minutes = String(currentDate.getMinutes()).padStart(2, '0');
//             // // Combine date and time components into the desired format
//             // const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}`;

//             // formvalue.RequestPickUpTime = formattedDateTime;
//             const currentDate = new Date();
//             currentDate.setHours(currentDate.getHours() + 4); // Add 4 hours

//             // Get the updated date and time components
//             const year = currentDate.getFullYear();
//             const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
//             const day = String(currentDate.getDate()).padStart(2, '0');
//             const hours = String(currentDate.getHours()).padStart(2, '0');
//             const minutes = String(currentDate.getMinutes()).padStart(2, '0');

//             // Combine date and time components into the desired format
//             const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}`;
//             formvalue.RequestPickUpTime = formattedDateTime;
//             formvalue.BookingType = 'booknow';
//             // debugger;
//         }
//         return errors;
//     };
    //get user current location ===================================================
    const getUserCurrentLocation = () => {
        navigator.permissions.query({ name: 'geolocation' }).then(function (result) {
            if (result.state == 'granted') {
                getLocation();

            } else if (result.state == 'prompt') {
                report(result.state);
            } else if (result.state == 'denied') {
                report(result.state);
            }
            result.onchange = function () {
                report(result.state);
            }
        });
    }
    function getLocation() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition);
        } else {
            alert("not conneted");
        }
    }
    function showPosition(position) {
        var latval = position.coords.latitude;
        var lonval = position.coords.longitude;

        // console.log('lat value-', latval.toString());
        // console.log('lon value-', lonval.toString());

        formvalue.Pickuplat = latval.toString();
        formvalue.Pickuplon = lonval.toString();

        get_EstimateFare();
        //  console.log(formvalue);

        fromLatLng(position.coords.latitude, position.coords.longitude)
            .then(({ results }) => {
                var address = results[0].formatted_address;
                // // document.getElementById('id_userlocation').innerHTML = '';
                // //  document.getElementById('id_userlocation').innerHTML = address;
                // document.getElementById('PickupAddress').value = address;


                const PickupAddresselement = document.getElementById('PickupAddress');
                if (PickupAddresselement) {
                    PickupAddresselement.value = address;
                } else {
                    console.warn("Element with id 'PickupAddress' not found.");
                }
                

            })
            // .catch(console.error);
    }
    function report(state) {
        // console.log('Permission ' + state);
        //alert(state);
    }
    // end get user current location =============================================

    // on load auto select ..............
    function onLoadTo(autocomplete) {
        setSearchResultTo(autocomplete);
    }
    function onPlaceChangedFrom() {
        if (searchResultFrom != null) {
            const place = searchResultFrom.getPlace();
            var latval = place.geometry.location.lat();
            var lonval = place.geometry.location.lng();

            // console.log('Pickup lat value-', latval.toString());
            // console.log(' Pickup lon value-', lonval.toString());

            formvalue.Pickuplat = latval.toString();
            formvalue.Pickuplon = lonval.toString();

        } else {
            alert("Please enter text for From Location");
        }
    }
    function onLoadFrom(autocomplete) {
        getUserCurrentLocation();
        setSearchResultFrom(autocomplete);
    }
    function onPlaceChangedTo() {
        if (searchResultTo != null) {
            const place = searchResultTo.getPlace();
            var latval = place.geometry.location.lat();
            var lonval = place.geometry.location.lng();

            // console.log('drop lat value-', latval.toString());
            // console.log(' drop lon value-', lonval.toString());

            formvalue.DropOfflat = latval.toString();
            formvalue.DropOfflon = lonval.toString();
            get_EstimateFare();


        } else {
            alert("Please enter text for To Location");
        }
    }
    if (!isLoaded) {

        return <div>Loading...</div>;
    }

    //end section.=================


    async function BookNow1(formvalue) {
        try {
            //console.log('formvalue-' + formvalue);
            //let result = await fetch('http://192.168.1.121:83/api/Booking/bookairportvechicle', {
            let result = await fetch('https://localhost:7291/api/Booking/bookairportvechicle', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "text/plain"
                },
                body: JSON.stringify(formvalue)
            });
            result = await result.json();
            // console.log('result--', result);
            if ((result.statusCode === 200) && (result.statusMessage === 'Booking Successfully Done.') && (result.bookingId != '')) {
                Swal.fire(
                    'Successfully Done!',
                    'You Booking Successfully Done!',
                    'success'
                )
                //navigate('/Dashboard');
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'test-' + result.statusMessage,

                })
            }
        } catch (errors) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: errors.title,

            })
        }
    }
    //console.log('profile data',getPreData);
    const inputstyles = {
        backgroundColor: "#fff",
        padding: "0 2em",
        border: "none",
        padding: '0px'
    }

    const haversineDistance = (lat1, lon1, lat2, lon2) => {
     const R = 6371; // Radius of the Earth in km 
    const dLat = (lat2 - lat1) * Math.PI / 180;
     const dLon = (lon2 - lon1) * Math.PI / 180; 
     const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * Math.sin(dLon / 2) * Math.sin(dLon / 2); 
     const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a)); const distance = R * c; // Distance in km 
     return distance;
      };
function CheckForSevicableArea(){
    var airportlat = 13.198341;
    var airportlon = 77.707733;
    
    
    // const DISTANCEBWatoc=haversineDistance(airportlat, airportlon, formvalue.Pickuplat, formvalue.Pickuplon);
    // const DISTANCEBWatob=haversineDistance( formvalue.DropOfflat, formvalue.DropOfflon,formvalue.Pickuplat,formvalue.pickuplon);
    // const DISTANCEBWctoa=haversineDistance(airportlat, airportlon, formvalue.DropOfflat, formvalue.DropOfflon);
    const DISTANCEBWatoc = haversineDistance(airportlat, airportlon, formvalue.Pickuplat, formvalue.Pickuplon);
     const DISTANCEBWatob = haversineDistance(formvalue.DropOfflat, formvalue.DropOfflon, formvalue.Pickuplat, formvalue.Pickuplon);
    const DISTANCEBWctoa=haversineDistance(airportlat, airportlon, formvalue.DropOfflat, formvalue.DropOfflon);
   
    return { distanceFromAirportToPickup: DISTANCEBWatoc, distanceFromDropOffToAirport: DISTANCEBWctoa, distanceBetweenPickupAndDropOff: DISTANCEBWatob };
}


    async function BookNow3() {
        var CallerName = document.getElementById("CallerName").value;
        var email = document.getElementById("emailId").value;
        var tripType = document.getElementById('selectedTripId').value;
        var vm = document.getElementById("vehiclemodal");
        var TaxiCategory = vm.options[vm.selectedIndex].text;
        var e = document.getElementById("TariffId");
        var Tariff = e.options[e.selectedIndex].text;
        var PickupAddress = document.getElementById("PickupAddress").value;
        var DropOffAddress = document.getElementById("dropAddress").value;
        var amount = document.getElementById("fareValueId").innerHTML;
        localStorage.setItem('name', CallerName);
        localStorage.setItem('email', email);
        localStorage.setItem('contactno', CallerName);
        localStorage.setItem('fromlocation', PickupAddress);
        localStorage.setItem('tolocation', DropOffAddress);
        localStorage.setItem('triptype', Tariff);
        localStorage.setItem('vehiclecategory', TaxiCategory);
        localStorage.setItem('bookingdate', formvalue.RequestPickUpTime);
        localStorage.setItem('amount', amount);

        navigate('/Bookingconfirm');
    }

    async function get_EstimateFare() {

        // alert('hi...');
        var Pickuplat = formvalue.Pickuplat;
        var pickuplon = formvalue.Pickuplon;

        var DropOfflat = formvalue.DropOfflat;
        var DropOfflon = formvalue.DropOfflon;

        const response = await fetch(`${server}/api/Dashboard/getlocationwithdistance/` + Pickuplat + `/` + pickuplon + `/` + DropOfflat + `/` + DropOfflon);
        const body = await response.json();

        var dist_duration = body.statusMessage.split('|', 2);

        // console.log('distance', dist_duration[0]);
        // console.log('duration', dist_duration[1]);

        var distanceKm = (parseFloat(dist_duration[0]) / 1000);
        var durationMinut = (parseFloat(dist_duration[1]) / 60);

        // console.log('distance-KM', distanceKm);
        // console.log('duration-Minutes', durationMinut);
document.getElementById("calculated_distance").innerHTML=distanceKm.toFixed(2);
        //get estimate fare..

        var selectTripId = document.getElementById("TariffId");
        var TariffId = selectTripId.value;
        var tripTypeId = "13";

        const apiUrl = 'https://captain-api.eveelz.in/driverapp/getestimatedfare';
        const postData = {
            "CompanyId": Company_Id,
            "GeoLocationId": "1",
            "EmpId": "0",
            "RoleId": "3",
            "LoginId": "0",
            "TariffId": TariffId,
            "TripTypeId": tripTypeId,
            "Distance": distanceKm,
            "VendorId": Vendor_Id,
            "Time": durationMinut,
            "EmployeeTypeId": "1",
            "CorporateId": Corporate_Id,
            "FromLat": Pickuplat,
            "FromLon": pickuplon,
            "ToLat": DropOfflat,
            "ToLon": DropOfflon
        };

        axios.post(apiUrl, postData)
            .then(response => {
                // console.log('Estimated Fare:', response.data.response.data[0].EstimatedFare);
                var estimateFareValue = response.data.response.data[0].EstimatedFare
                document.getElementById("fareValueId").innerHTML = 0;
                document.getElementById("fareValueId").innerHTML = estimateFareValue.toFixed(2);
                //document.getElementById("fareValueId").innerHTML = response.data.response.data[0].EstimatedFare;

                // formvalue.CalculatedBaseFare=response.data.response.data[0].EstimatedFare;
                // Handle response data as needed
            })
            .catch(error => {
                // console.error('Error:', error);
                // Handle errors
            });

    }

    return (
        <>
            {/* <Booking formvalue={formvalue}></Booking> */}
            <div>
                <MasterPage />
            </div>
            <div>
                <div className="content-wrapper" style={{ minHeight: '704.8px' }}>
                    {/* Content Header (Page header) */}
                    <section className="content-header">
                        <div className="container-fluid">
                            <div className="row mb-2">
                                <div className="col-sm-6">
                                    <h1>Booking</h1>
                                </div>
                                <div className="col-sm-6">
                                    <ol className="breadcrumb float-sm-right">
                                        <li className="breadcrumb-item"><a href="#">Home</a></li>
                                        <li className="breadcrumb-item active">Booking</li>
                                    </ol>
                                </div>
                            </div>
                        </div>{/* /.container-fluid */}
                    </section>
                    {/* Main content */}
                    <section className="content">
                        <div className="container-fluid">
                            <div className="card card-default">
                                <div className="card-header">
                                    <h3 className="card-title">Booking Details</h3>
                                    {/* <button onClick={get_EstimateFare}>Check</button> */}

                                </div>
                                <div className="card-body">
                                    <form onSubmit={handleSubmit}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <div className="callout callout-warning">
                                                    <h5>Full Name</h5>
                                                    <p>
                                                        <input id='CallerName' value={userName} readOnly type="text" name='CallerName' className="form-control" required style={inputstyles} />
                                                        {/* <p className='text-danger'>{formErrors.CallerName}</p> */}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <div className="callout callout-warning">
                                                    <h5>Email Id</h5>
                                                    <p>
                                                        <input id='emailId' value={userEmailId} readOnly type="email" name='emailId' className="form-control" required style={inputstyles} />
                                                        {/* <p className='text-danger'>{formErrors.emailId}</p> */}
                                                    </p>
                                                </div>
                                            </div>
                                            <div className='col-md-4'>
                                                <div className="callout callout-warning">
                                                    <h5>Contact No.</h5>
                                                    <p>
                                                        <input id='mobileNo' readOnly type="email" value={userMobNo} className="form-control" style={inputstyles} />

                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row'>

                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>From Location </label>
                                                    {/* <p><i className="fa fa-map-marker" aria-hidden="true" /> &nbsp;&nbsp;
                                                        <span id='id_userlocation'></span></p> */}
                                                    <div className="input-group date" id="reservationdatetime" data-target-input="nearest">
                                                        {/* <input id='fromlocation' type="text" className="form-control datetimepicker-input" data-target="#reservationdatetime" /> */}
                                                        <Autocomplete onPlaceChanged={onPlaceChangedFrom} onLoad={onLoadFrom}>
                                                            <input onChange={handChange}
                                                                name='PickupAddress'
                                                                type="text" id='PickupAddress' placeholder="From Location"
                                                                style={{
                                                                    boxSizing: 'border-box',
                                                                    border: '1px solid transparent',
                                                                    width: '594px',
                                                                    height: '36px',
                                                                    padding: '0 12px',
                                                                    borderRadius: '3px',
                                                                    border: '1px solid #ccc',
                                                                    fontSize: '14px',
                                                                    outline: 'none',
                                                                    textOverflow: 'ellipses',
                                                                }}
                                                            />
                                                        </Autocomplete>
                                                    </div>
                                                    <p className='text-danger'>{formErrors.PickupAddress}</p>
                                                </div>

                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group">
                                                    <label>To Location</label>
                                                    <div className="input-group date" id="reservationdatetime" data-target-input="nearest">
                                                        {/* <input type="text" className="form-control datetimepicker-input" data-target="#reservationdatetime" /> */}
                                                        <Autocomplete onPlaceChanged={onPlaceChangedTo} onLoad={onLoadTo}>
                                                            <input onChange={handChange} name='DropOffAddress'
                                                                type="text" placeholder="To Location" id='dropAddress'
                                                                style={{
                                                                    boxSizing: 'border-box',
                                                                    border: '1px solid transparent',
                                                                    width: '594px',
                                                                    height: '36px',
                                                                    padding: '0 12px',
                                                                    borderRadius: '3px',
                                                                    border: '1px solid #ccc',
                                                                    fontSize: '14px',
                                                                    outline: 'none',
                                                                    textOverflow: 'ellipses',
                                                                }}

                                                            />
                                                        </Autocomplete>
                                                    </div>
                                                    <p className='text-danger'>{formErrors.DropOffAddress}</p>
                                                </div>
                                            </div>


                                        </div>
                                        <div className='row'>

                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Vehicle Category </label>
                                                    <select name='TaxiCategoryId' id='vehiclemodal' className="form-control select2" style={{ width: '100%' }}>

                                                                            {vechaleCat && vechaleCat.length > 0 ? (
                                                            vechaleCat.map(item => (
                                                                <option key={item.vechalCatId} value={item.vechalCatId}>
                                                                    {item.categoryName}
                                                                </option>
                                                            ))
                                                        ) : (
                                                            <option value="">No categories available</option>
                                                        )}

                                                    </select>
                                                    <p className='text-danger'>{formErrors.TaxiCategoryId}</p>
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Trip Type {formvalue.TariffId} </label>

                                                    {/* {formvalue.TariffId} */}
                                                    <input id='selectedTripId' type='hidden' value={formvalue.TariffId}></input>
                                                    <select name='TariffId' id='TariffId' className="form-control select2" style={{ width: '100%' }} onChange={get_EstimateFare}>

                                                    { tripType && tripType.length > 0 ? ( tripType.map(item => ( <option key={item.tariffID} value={item.tariffID}> {item.tariffName} </option> )) ) : ( <option value="">No Data</option> ) }
                                                    </select>
                                                    {/* {
                                                        formvalue.TariffId == 2 && formvalue.TariffId == 2 ?
                                                            (<span style={{ color: 'red', fontSize: '12px' }}>Service is not activated</span>) : (<span></span>)
                                                    } */}
                                                    <p className='text-danger' style={{ fontSize: '12px' }}>{formErrors.TariffId}</p>
                                                </div>
                                            </div>

                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Trip Base Fare</label>
                                                    <h5 className="form-control select2" style={{ color: 'green' }} id='fareValueId'>
                                                        {/* {
                                                            tripType.map(item => (
                                                                item.baseFare
                                                            ))
                                                        } */}
                                                    </h5>
                                                    <p className='text-danger'>{formErrors.BookingFare}</p>
                                                </div>
                                            </div>
                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <label>Distance in (KM)</label>
                                                    <h5 className="form-control select2" style={{ color: 'green' }} id='calculated_distance'>
                                                        
                                                    </h5>
                                                   
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row' style={{ paddingTop: '20px' }}>
                                            <div className='col-md-3'>
                                                <div className="icheck-primary d-inline">
                                                    <input onChange={handChange}
                                                        type="radio"
                                                        name="r1"
                                                        value="booknow"
                                                        id="rbBookNow"
                                                    />
                                                    <label for="rbBookNow">
                                                        Book Now
                                                    </label> &nbsp;&nbsp;&nbsp;
                                                </div>
                                                <div className="icheck-primary d-inline">
                                                    <input onChange={handChange}
                                                        type="radio"
                                                        name="r1"
                                                        value="bookltr"
                                                        id="rbBookltr"
                                                    />
                                                    <label for="rbBookltr">
                                                        Book Later
                                                    </label>
                                                </div>
                                                <p className='text-danger'>{formErrors.r1}</p>
                                            </div>

                                            <div className="col-md-3">
                                                {
                                                    formvalue.r1 === "bookltr" ? (
                                                        <div>
                                                            <div className="form-group">
                                                                <div className="input-group date" id="reservationdatetime11" data-target-input="nearest">
                                                                    <input id="reservationdate" onChange={handChange} name='RequestPickUpTime' value={formvalue.RequestPickUpTime} type="datetime-local" className="form-control" />
                                                                </div>

                                                                <p className='text-danger'>{formErrors.RequestPickUpTime}</p>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <div>

                                                        </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        <div className='row' style={{ marginTop: '30px' }}>

                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <div className="input-group date" id="reservationdatetime" data-target-input="nearest">
                                                        {/* <input type="button" onClick={BookNow} className="btn btn-warning" value='Book a Ride' /> */}
                                                        <button className="btn btn-warning btn-block">Book a Ride</button>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="col-md-3">
                                                <div className="form-group">
                                                    <div className="input-group date" id="reservationdatetime" data-target-input="nearest">

                                                        {/* <input type="button" onClick={BookNow3} className="btn btn-warning" value='Book a Ride' /> */}
                                                    </div>
                                                </div>

                                            </div>




                                        </div>
                                    </form>
                                </div>
                                {/* /.card-body */}
                            </div>
                        </div>{/*/. container-fluid */}
                    </section>
                    {/* /.content */}
                </div>
            </div>
            <div>
                <Footer />
            </div>
        </>
    )
}
export default Booking